'use server';

import { getToken } from './Auth';

export const GET = async ({ endpoint, extra, extras }) => {
  try {
    let token = await getToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    let url = `${process.env.REACT_APP_API_URL}/${endpoint}`;

    if (extras) {
      const queryString = new URLSearchParams(extras).toString();
      url = `${url}?${queryString}`;
    }

    if (extra) {
      url = `${url}/${extra}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    return await response.json();
  } catch (error) {
    return error;
  }
};

export const AUTHPOST = async ({ endpoint, body, extra }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    );

    const token =
      response.headers.get('authorization') ||
      response.headers.get('x-auth-token');

    const data = await checkResponse(response);

    return { token, data };
  } catch (error) {
    throw new Error(error.message || error || `Something went wrong`);
  }
};

export const POST = async ({ endpoint, body, extra }) => {
  try {
    let token = await getToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      }
    );
    return await checkResponse(response);
  } catch (error) {
    throw new Error(error.message || error || `Something went wrong`);
  }
};

const checkResponse = async (response) => {
  if (!!response.ok) {
    try {
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      return { success: true };
    }
  } else {
    switch (response.status) {
      case 403:
        throw new Error(
          `${response?.status}: ${
            response?.statusText || 'Something went wrong'
          }`
        );
      case 404:
        throw new Error(
          `${response?.status}: ${
            response?.statusText || 'Something went wrong'
          }`
        );
      default:
        throw new Error((await response?.json()) || 'Unknown error occured');
    }
  }
};
