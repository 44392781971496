import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/Auth/Auth';
import AgenciesReducer from './slices/Agency/Agencies';
import ContractReducer from './slices/Contract/Contract';

const rootReducer = combineReducers({
  auth: authReducer,
  agencies: AgenciesReducer,
  contracts: ContractReducer
  // e.g. someOtherSlice: someOtherReducer,
});

export default rootReducer;
